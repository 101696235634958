import { Typography, Stack, Paper } from '@mui/material';
import React from 'react';

const Contact = () =>{
  return(
    <React.Fragment>
      <Stack sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <Paper elevation={1} sx={{display:'flex', justifyContent:'center', alignItems:'center', m:8, mb:14}}>
        <Stack>
        <Stack sx={{m:4, justifyContent:'center', alignItems:'center'}}>
          <Typography>
          Bize şu mail adresinden ulaşabilirsiniz:
          </Typography>
          <Typography sx={{color:'primary.main'}}>
          biradimproject@gmail.com
          </Typography>
          </Stack>

          <Stack sx={{m:5, justifyContent:'center', alignItems:'center'}}>
          <Typography>
            Ekip Üyelerimiz
          </Typography>
          <Typography>
          Cemre Ateş - Sıla Doğa Başaran - Ece Naz Kaya
          </Typography>
          <Typography>
          Elif Meteris - Neris Görener
          </Typography>
          </Stack>
          </Stack>
        </Paper>
      </Stack>
    </React.Fragment>
  );
}

export default Contact;
