import React, { useEffect, useState, useContext } from 'react';
import { Typography, Paper, useMediaQuery, Stack, Button, Card, CardContent, Box, Divider, FormControl, Grid, ListItemIcon, Select, MenuItem, InputLabel, Dialog, AppBar, Toolbar, List, ListItem, ListItemText, IconButton, Slide, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { UserContext } from '../contexts/user.context';
import CloseIcon from '@mui/icons-material/Close';
import {
    auth,
} from "../utils/firebase/firebase.utils";
import { useAuthState } from "react-firebase-hooks/auth";
import { styled, useTheme } from '@mui/material/styles';
import { teacherInputFunc, retrieveTeacherComments } from '../utils/firebase/firebase.utils';

export default function Comments() {

    const { currentUser } = useContext(UserContext)

    const [user, loading, error] = useAuthState(auth);
    const [selectValue, setSelectValue] = React.useState('')
    const [qList, setQList] = useState([]);
    const [opentwo, setOpenTwo] = React.useState(false);

    const theme = useTheme();

    let userDetails = '';
    if (typeof (currentUser) !== 'undefined') {
        userDetails = currentUser;
    }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const schoolContact = currentUser.email + ' ' + currentUser.telefon;
        console.log(schoolContact)
        await teacherInputFunc(currentUser.uid, data.get('stuspecials'), data.get('teacherInputText'), currentUser.name, schoolContact, selectValue);
    };

    const handleChange = (event) => {
        setSelectValue(event.target.value);
        console.log(userDetails.email);
    };


    const handleCloseTwo = () => {
        setOpenTwo(false);
    };

    const retrieveQuestionsStu = async (stuuid) => {
        const data = await retrieveTeacherComments(stuuid);
        setQList(data);
        setOpenTwo(true);
    }

    const handleSubmitTwo = async (event) => {
        event.preventDefault();
        const datatwo = new FormData(event.currentTarget);
        await retrieveQuestionsStu(datatwo.get('stuspecial'));
    };
    const StyledPaperFr = styled(Stack)(({ theme }) => ({
        backgroundColor: 'primary.main',
        elevation: 0,
        border: '2px solid gray',
        height: 700,
        width: 500,
        marginBottom: 20,
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            width: 450,
        }
    }));

    const StyledPaperDialog = styled(Paper)(({ theme }) => ({
        backgroundColor: 'primary.main',
        elevation: 0,
        border: '2px solid gray',
        minHeight: 200,
        maxHeight: '100%',
        width: 700,
        marginBottom: 20,
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            width: 450,
        }
    }));

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'studentName', headerName: 'Öğrenci Adı', width: 130 },
        { field: 'studentId', headerName: 'Özel Kodu', width: 300 },

    ]
    const rows = [
    ];
    let counter = 1;

    if (userDetails.roles === 'teacher') {
        for (let i = 0; i < userDetails.students.length; i++) {
            rows.push({ id: counter, studentName: userDetails.studentName[i], studentId: userDetails.students[i] });
            counter++;
        }
    }
    var commentsMath = [];
    var commentsScience = [];
    var commentsTurkish = [];
    var commentsEnglish = [];
    var commentsReligion = [];
    var commentsSocial = [];

    for (const key in qList) {
        if (qList[key].teacherField === "Matematik") {
            commentsMath.push({ teacherInput: qList[key].teacherInput, teacherName: qList[key].teacherName, teacherField: qList[key].teacherField, teacherContact: qList[key].teacherContact, });
        }
        else if (qList[key].teacherField === "Fen") {
            commentsScience.push({ teacherInput: qList[key].teacherInput, teacherName: qList[key].teacherName, teacherField: qList[key].teacherField, teacherContact: qList[key].teacherContact, });
        }
        else if (qList[key].teacherField === "Türkçe") {
            commentsTurkish.push({ teacherInput: qList[key].teacherInput, teacherName: qList[key].teacherName, teacherField: qList[key].teacherField, teacherContact: qList[key].teacherContact, });
        }
        else if (qList[key].teacherField === "İngilizce") {
            commentsEnglish.push({ teacherInput: qList[key].teacherInput, teacherName: qList[key].teacherName, teacherField: qList[key].teacherField, teacherContact: qList[key].teacherContact, });
        }
        else if (qList[key].teacherField === "Sosyal") {
            commentsSocial.push({ teacherInput: qList[key].teacherInput, teacherName: qList[key].teacherName, teacherField: qList[key].teacherField, teacherContact: qList[key].teacherContact, });
        }
        else if (qList[key].teacherField === "Din") {
            commentsReligion.push({ teacherInput: qList[key].teacherInput, teacherName: qList[key].teacherName, teacherField: qList[key].teacherField, teacherContact: qList[key].teacherContact, });
        }
    }

    const DialogStuDetails = (
        <React.Fragment>
            <Dialog
                fullScreen
                open={opentwo}
                onClose={handleCloseTwo}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseTwo}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                            Öğretmenlerinden yorumlar
                        </Typography>

                    </Toolbar>
                </AppBar>
                <Stack sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <StyledPaperDialog >
                        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>

                            <Typography>Matematik Yorumları</Typography>
                            <Grid direction="column" sx={{ justifyContent: 'start', alignItems: 'start', display: 'flex', overflow: 'auto', }}>
                                {commentsMath.map((comment) =>
                                    <Card sx={{ minHeight: 100, minWidth: 350, m: 3, display: 'flex' }}>
                                        <CardContent>
                                            <Typography variant="h7" component="div" >
                                                {comment.teacherName}, {comment.teacherField}
                                            </Typography>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" sx={{ mb: 3 }} gutterBottom>
                                                {comment.teacherContact}
                                            </Typography>

                                            <Typography variant="body2">
                                                {comment.teacherInput}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )}
                            </Grid>
                        </Stack>

                    </StyledPaperDialog>
                    <StyledPaperDialog >
                        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Typography>Fen Yorumları</Typography>
                            <Grid direction="column" sx={{ justifyContent: 'start', alignItems: 'start', display: 'flex', overflow: 'auto', }}>
                                {commentsScience.map((comment) =>
                                    <Card sx={{ minHeight: 100, minWidth: 350, m: 3, display: 'flex' }}>
                                        <CardContent>
                                            <Typography variant="h7" component="div" >
                                                {comment.teacherName}, {comment.teacherField}
                                            </Typography>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" sx={{ mb: 3 }} gutterBottom>
                                                {comment.teacherContact}
                                            </Typography>

                                            <Typography variant="body2">
                                                {comment.teacherInput}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )}
                            </Grid>
                        </Stack>
                    </StyledPaperDialog>
                    <StyledPaperDialog >
                        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Typography>İngilizce Yorumları</Typography>
                            <Grid direction="column" sx={{ justifyContent: 'start', alignItems: 'start', display: 'flex', overflow: 'auto', }}>
                                {commentsEnglish.map((comment) =>
                                    <Card sx={{ minHeight: 100, minWidth: 350, m: 3, display: 'flex' }}>
                                        <CardContent>
                                            <Typography variant="h7" component="div" >
                                                {comment.teacherName}, {comment.teacherField}
                                            </Typography>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" sx={{ mb: 3 }} gutterBottom>
                                                {comment.teacherContact}
                                            </Typography>

                                            <Typography variant="body2">
                                                {comment.teacherInput}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )}
                            </Grid>
                        </Stack>
                    </StyledPaperDialog>
                    <StyledPaperDialog >
                        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Typography>Sosyal Bilgiler Yorumları</Typography>

                            <Grid direction="column" sx={{ justifyContent: 'start', alignItems: 'start', display: 'flex', overflow: 'auto', }}>
                                {commentsSocial.map((comment) =>
                                    <Card sx={{ minHeight: 100, minWidth: 350, m: 3, display: 'flex' }}>
                                        <CardContent>
                                            <Typography variant="h7" component="div" >
                                                {comment.teacherName}, {comment.teacherField}
                                            </Typography>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" sx={{ mb: 3 }} gutterBottom>
                                                {comment.teacherContact}
                                            </Typography>

                                            <Typography variant="body2">
                                                {comment.teacherInput}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )}
                            </Grid>
                        </Stack>
                    </StyledPaperDialog>
                    <StyledPaperDialog >
                        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Typography>Din Yorumları</Typography>
                            <Grid direction="column" sx={{ justifyContent: 'start', alignItems: 'start', display: 'flex', overflow: 'auto', }}>
                                {commentsReligion.map((comment) =>
                                    <Card sx={{ minHeight: 100, minWidth: 350, m: 3, display: 'flex' }}>
                                        <CardContent>
                                            <Typography variant="h7" component="div" >
                                                {comment.teacherName}, {comment.teacherField}
                                            </Typography>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" sx={{ mb: 3 }} gutterBottom>
                                                {comment.teacherContact}
                                            </Typography>

                                            <Typography variant="body2">
                                                {comment.teacherInput}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )}
                            </Grid>
                        </Stack>
                    </StyledPaperDialog>
                </Stack>
            </Dialog>
        </React.Fragment>
    );



    const Form = (
        <React.Fragment>
            <Stack component="form" direction="columns" onSubmit={handleSubmit} sx={{ alignItems: 'start', justifyContent: 'start', mt: 5 }}>
                <Stack direction="column" sx={{ alignItems: 'start' }}>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>Bir öğrenciye yorum ekle</Typography>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 5, mt: 1 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Alan</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectValue}
                                    defaultValue={selectValue}
                                    label="Ana Dil"
                                    displayEmpty
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"Matematik"}>Matematik</MenuItem>
                                    <MenuItem value={"Fen"}>Fen</MenuItem>
                                    <MenuItem value={"Türkçe"}>Türkçe</MenuItem>
                                    <MenuItem value={"İngilizce"}>Ingilizce</MenuItem>
                                    <MenuItem value={"Sosyal"}>Sosyal Bilimler/Tarih</MenuItem>
                                    <MenuItem value={"Din"}>Din</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h7">Öğrencinin özel kodu</Typography>
                        </Box>
                        <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 3, mt: 1 }}>
                            <TextField
                                sx={{ width: '150ch' }}
                                required
                                name="stuspecials"
                                id="outlined-required"
                                label=""
                            />
                        </Box>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h7">Yorumunuz</Typography>
                        </Box>
                        <Box sx={{ width: 300, height: 350, display: 'flex', justifyContent: 'center', mb: 3, mt: 1 }}>
                            <TextField
                                sx={{ width: '150ch' }}
                                required
                                name="teacherInputText"
                                id="outlined-multiline-flexible"
                                multiline
                                minRows={14}
                                label=""
                            />
                        </Box>
                        <Button color="secondary" variant="contained" type="submit" autoFocus sx={{ color: 'white', display: 'flex', borderRadius: 3 }} onClick={()=> {alert('basarili')}}>
                            Kaydet
                        </Button>
                    </Stack>
                </Stack>

            </Stack>
        </React.Fragment>
    );


    const dialogStudent = (
        <Stack component="form" direction="columns" onSubmit={handleSubmitTwo} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack sx={{ alignItems: 'center', m:3 }}>
            <Typography variant='h5' sx={{mb:3}}> Öğrencinin önceki yorumlarını gör</Typography>
                <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h7">Öğrencinin özel kodu</Typography>
                </Box>
                <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 3, mt: 1 }}>
                    <TextField
                        sx={{ width: '150ch' }}
                        required
                        name="stuspecial"
                        id="outlined-required"
                        label=""
                    />
                </Box>
                <Button color="secondary" autoFocus sx={{ color: 'white', display: 'flex', borderRadius: 3, textTransform:'none' }} variant="contained" type="submit" onClick={() =>retrieveQuestionsStu()}>
                Önceki yorumları getir
            </Button>
            {DialogStuDetails}

            </Stack>

        </Stack>
    );

    return (
        <React.Fragment>
            <Stack direction={{ xl: 'row', lg: 'row', sm: 'column', xs: 'column' }} spacing={3} sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <StyledPaperFr direction="row" sx={{ alignItems: 'start' }}>
                    {Form}
                </StyledPaperFr>
                <div style={{ height: 600, width: 600 }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                    />
                </div>
                <StyledPaperFr direction="row" sx={{ alignItems: 'center' }}>
                    {dialogStudent}
                </StyledPaperFr>
            </Stack>
        </React.Fragment>
    );
}



