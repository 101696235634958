import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import mainpage from '../assets/mainpage.svg';
import lakome from '../assets/lakome.svg';
import lakome1 from '../assets/lakome1.svg';
import lakome2 from '../assets/lakome2.svg';

import theme  from '../components/ui/theme';

import { Stack, Grid } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../contexts/user.context.jsx';
import Dashboard from './dashboard.js';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import { styled } from '@mui/material/styles';
import Login from '../components/login.component';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const TypographyTwo = styled(Typography)({
  color: 'white',
  fontSize: 30,
});

const Home = () => {
  const { currentUser } = useContext(UserContext);

  var items = [
    {
      name: "Geleceğe bir adım",
      qone: "Eğitim sürecin platform üzerinde kaydedilir, böylelikle öğretmenlerin senin eksiklerini önceden bilerek sana özel bir yaklaşım hazırlayabilir.",
      qtwo: "Müfredattaki eksiklerini sistem üzerinde sunduğumuz müfredat planı ve sana özel hazırladığımız kaynaklardan tamamlayabilirsin.",
      qthree: "Kaynak paylaşımı bölümünde kaynaklarını paylaşarak platform üzerindeki diğer göçmen/mülteci çocuklarla dayanışma içinde olabilirsin."
    },
    {
      name: "Take a step towards the future",
      qone: "Your education level in each subject can be recorded, thus your teachers can see your level even before school starts so they can prepare a special approach for you.",
      qtwo: "You can complete the points you missed in the syllabus of previous year's classes via the resources prepared specially for you.",
      qthree: "You can always colloborate on the resource sharing page with other refugee children by sharing notes in your strongest areas(combining your native language and the class language)."
    },
    {
      name: "اتخذ خطوة نحو المستقبل",
      qone: "يتم تسجيل عملية التدريب الخاصة بك على المنصة ، حتى يتمكن مدرسك من إعداد نهج مخصص لك من خلال معرفة أوجه القصور لديك مسبقًا.",
      qtwo: "هذا التدريب العملي الخاص بك على المنصة حتى عامين مدرسك",
      qthree: "يمكنك أن تتضامن مع الأطفال المهاجرين / اللاجئين الآخرين على المنصة من خلال مشاركة مواردك في قسم مشاركة الموارد."
    },
    {
      name: "یو ګام مخکې واخله",
      qone: "ستاسو د روزنې بهیر په پلیټ فارم کې ثبت شوی، ترڅو ستاسو ښوونکي ستاسو د نیمګړتیاوو په پوهیدو سره ستاسو لپاره مناسب چلند چمتو کړي..",
      qtwo: "يتم تسجيل عملية التدريب الخاصة بك على المنصة ح حتى يتمكن مدرسك من إعداد نهج مخص لك من خلال معر خلال أجر قلي قيق",
      qthree: "تاسو کولی شئ د منابعو د شریکولو برخه کې د خپلو سرچینو په شریکولو سره په پلیټ فارم کې د نورو مهاجرو/کډوالو ماشومانو سره پیوستون کې اوسئ."    },
    {
      name: "Ber bi paşerojê ve gav bavêjin",
      qone: "Pêvajoya perwerdehiya we li ser platformê tê tomar kirin, da ku mamosteyên we bi zanîna kêmasiyên we pêşwext ji we re nêzîkatiyek lihevhatî amade bikin.",
      qtwo: "Hûn dikarin kêmasiyên di mufredatê de ji plansaziya mufredatê ya ku em li ser pergalê pêşkêşî dikin û çavkaniyên ku me bi taybetî ji we re amade kirine pêk bînin.",
      qthree: "Bi parvekirina çavkaniyên xwe yên di beşa parvekirina çavkaniyê de hûn dikarin bi zarokên din ên koçber/penaber ên li ser platformê re bibin yek."
        }
  ]
  function CarouselCard(props) {
    return (
      <Paper sx={{ bgcolor: 'primary.main' }}>
        <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TypographyTwo wrap align="center">{props.item.name}</TypographyTwo>
            <Box component="img" sx={{
              height: "5em",
              m: 3
            }} disableRipple alt="logo" src={mainpage} />
          </Stack>
          <Stack sx={{m:3}}>
            <Grid container direction="row" rowSpacing={1} columnSpacing={{ xs: 3, sm: 3, md: 4 }} >
              <Grid item xs={12} md={4}>
              <Stack sx={{justifyContent:'center', alignItems:'center',display:'flex' , minWidth:300}}>
                <Item>
                <Box component="img" sx={{
                  height: "10em",
                  m: 2
                }} disableRipple alt="logo" src={lakome} />
                <Typography>{props.item.qone}</Typography>
                </Item>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
              <Stack sx={{justifyContent:'center', alignItems:'center',display:'flex' , minWidth:300}}>
                <Item>
                <Box component="img" sx={{
                  height: "10em",
                  m: 2
                }} disableRipple alt="logo" src={lakome1} />
                <Typography>{props.item.qtwo}</Typography>
                </Item>
                </Stack>
              </Grid>            
              <Grid item xs={12} md={4}>
              <Stack sx={{justifyContent:'center', alignItems:'center',display:'flex' , minWidth:300}}>
                <Item>
                <Box component="img" sx={{
                  height: "10em",
                  m: 2
                }} disableRipple alt="logo" src={lakome2} />
                <Typography>{props.item.qthree}</Typography>
                </Item>
                </Stack>
              </Grid>
            </Grid>
          </Stack>

        </Stack>
      </Paper>
    )
  }

  if (currentUser) {
    return (
      <Dashboard />
    )
  }

  else {
    return (
      <React.Fragment>
        <Stack direction="column" sx={{ display: 'flex', alignItems: 'center' }}>
          <Carousel sx={{ width: '100%', minHeight: 300, [theme.breakpoints.down("md")]:{minHeight:900} }} interval={3000}>
            {
              items.map((item, i) => <CarouselCard key={i} item={item} />)
            }
          </Carousel>
          <Login />
        </Stack>
      </React.Fragment>
    )
  }
}

export default Home;
