import styled from "@emotion/styled";
import {
  Grid,
  List,
  ListItemText,
  Typography,
  Button,
  Stack,
  Container,
  TextField
} from "@mui/material";
import { Box } from "@mui/system";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import SendIcon from "@mui/icons-material/Send";
import {Link} from "react-router-dom";

const FooterTitle = styled(Typography)(() => ({
  textTransform: "uppercase",
  marginBottom: "1em",
}));

const SubscribeTf = styled(TextField)(() => ({
  ".MuiInputLabel-root": {
    color: 'secondary.main',
  },

  ".MuiInput-root::before": {
    borderBottom: `1px solid secondary.main`,
  },
}));

export default function Footer() {
  return (
    <Box
      sx={{
        bgcolor: 'primary.main',
        color: 'white',
        p: { xs: 4, md: 10 },
        pt: 12,
        pb: 12,
        fontSize: { xs: '12px', md: '14px' }
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item md={6} lg={4} sx={{mr:3}}>
        <Typography variant="body1" sx={{textTransform:'none'}}> Hakkımızda </Typography>
          <Typography variant="caption2">
            Bir Adım projesi, öğretmenler ve öğrenciler arasındaki iletişimi kolaylaştırarak mülteci çocukların eğitimlerinde geri kalmamalarını ve eksik konularını tamamlamalarını sağlayan bir platformdur.
          </Typography>
          <Box
            sx={{
              mt: 4,
              color: 'primary.main',
            }}
          >
            <FacebookIcon sx={{ mr: 1 }} />
            <TwitterIcon sx={{ mr: 1 }} />
            <InstagramIcon />
          </Box>
        </Grid>
        <Grid item md={6} lg={2} sx={{mr:2}}>
          <Typography variant="body1" sx={{textTransform:'none'}}> Bilgi </Typography>
          <List>
            <ListItemText > 
              <Typography lineHeight={2} variant="caption2">
                Hakkımızda
              </Typography>
            </ListItemText>
            <ListItemText>
              <Typography lineHeight={2} variant="caption2">
                Privacy &amp; Policy
              </Typography>
            </ListItemText>
            <ListItemText>
              <Typography lineHeight={2} variant="caption2">
                Terms &amp; Conditions
              </Typography>
            </ListItemText>
          </List>
        </Grid>
      
        <Grid item md={6} lg={4}>
        <Typography variant="body1" sx={{textTransform:'none'}}> Bize Ulaşın </Typography>
        <Stack>
            <SubscribeTf
              color="primary"
              label="Email address"
              variant="standard"
            />
            <Button
              startIcon={<SendIcon sx={{ color: "white", textTransform: 'none'}} />}
              sx={{ mt: 4, mb: 4, color:'white'}}
              variant="contained"
            >
              Bize Ulaşın
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
