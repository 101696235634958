import React from 'react';
import { useContext } from 'react';
import { UserContext } from '../contexts/user.context.jsx';
import { Typograph, Box, Tab, } from '@mui/material/';
import { TabContext, TabList, TabPanel } from '@mui/lab/';
import Profile from './profile.js';
import Comments from './comments.js';

const Dashboard = () => {
  const { currentUser } = useContext(UserContext);
  const [value, setValue] = React.useState('1');

  let matches = false;
  if (currentUser.roles === 'teacher') {
    matches = true;
  }


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TeacherTab = (<Box sx={{ width: '100%', typography: 'body1' }}>
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
          <Tab sx={{ textTransform: "none" }} label="Profil" value="1" />
          <Tab sx={{ textTransform: "none" }} label="Yorumlar" value="2" />
          <Tab sx={{ textTransform: "none" }} label="Kaynaklar" value="3" />
        </TabList>
      </Box>
      <TabPanel value="1"><Profile /></TabPanel>
      <TabPanel value="2"><Comments /></TabPanel>
      <TabPanel value="3">Item Three</TabPanel>
    </TabContext>
  </Box>);


  const StudentTab = (<Box sx={{ width: '100%', typography: 'body1' }}>
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
          <Tab sx={{ textTransform: "none" }} label="Profil" value="1" />
          <Tab sx={{ textTransform: "none" }} label="Kaynaklar" value="2" />
        </TabList>
      </Box>
      <TabPanel value="1"><Profile /></TabPanel>
      <TabPanel value="2">Item Three</TabPanel>
    </TabContext>
  </Box>);

 
  return (
    <React.Fragment>
    { matches ? TeacherTab : StudentTab }
    </React.Fragment>
  )
}


export default Dashboard;
