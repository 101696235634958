import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const Theme = createTheme({
  palette: {
      type: 'light',
      primary: {
        main: '#5CB3FE',
      },
      secondary: {
        main: '#fea85c',
      },
    },
});

export default Theme;
