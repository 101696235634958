import React from 'react';

import { Typography, Paper, Stack, useMediaQuery } from '@mui/material';
import { link } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';

export default function About() {

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const mobil = (
    <React.Fragment>
      <Stack direction="column" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <Paper elevation={3} sx={{ width: 600, height: 1150, justifyContent: 'center', alignItems: 'center', m: 4 }}>
          <Typography sx={{ m: 2, mt: 5 }}>
            Mülteci çocuklarının eğitimi projemizin temel amacı zor koşullar altında eğitim alan mülteci çocukların eğitimine destek olmak adına bir platform yaratmaktır. Yapılan araştırmalara göre 2020 yılı itibariyle ülkemizde yaklaşık dört milyon mülteci bulunmaktadır ve onların yaklaşık 1.74’ü çocuktur. Bu nedenle biz bu proje ile ülkemizde yaşamını sürdüren mülteci çocukların verimli bir şekilde eğitim alabilmelerini sağlamayı ve eğitimleri yarın kalan çocuklara destek olmayı amaçlıyoruz.
          </Typography>
          <Typography sx={{ m: 2 }}>
            Eğitim hayatları yarım kalan mülteci çocukların öğrenim süreçlerini takip edebilmelerini sağlayarak müfredatları tamamlamalarına yardımcı oluyoruz. Öğretmen ve öğrenci arasındaki iletişim engelini kaldırarak eğitim sürecini kolaylaştırmak ile beraber, farklı okullarda çalışan öğretmenler arası iletişimi kolaylaştırıyoruz . Mevsimlik işlerde çalışan aileleri sebebiyle farklı okullarda eğitimini sürdürmek zorunda kalan mülteci çocuklar hakkında en güncel verilere sahip olmalarına aracı oluyoruz. Geçmiş okullarındaki eğitim süreçlerini platforma kaydederek çocukların hangi konuda ne kadar bilgiye sahip olduklarını ölçme değerlendirmeye ihtiyaç duymadan öğretmenlere iletilmesini sağlıyoruz. Böylelikle öğretmenlerin yardımıyla mülteci çocukların müfredattaki eksiklerini hızlıca kapatmalarına yardımcı oluyoruz.
          </Typography>
          <Typography sx={{ m: 2 }}>
            Mülteci çocukların yaşadığı en büyük problem dil yetersizliğinden kaynaklanmaktadır, bu yüzden de okullarda bazı dersleri takip etmekte zorlanmaktadırlar. Biz bu projeyle ana dili aynı olan çocukları eşleştirip, akran öğrenmesi ile birbirlerine yardımcı olmasını sağlıyoruz. Üst dönemde halihazırda o dersi almış bir öğrencinin alt dönemdeki bir öğrenciye kendi ana dilini de kullanarak eğitim vermesinin en verimli öğrenmeyi sağlayacağı görüşünüzdeyiz. Örneğin 8. sınıf anadili Arapça olan bir öğrenci, 5. sınıftaki aynı şekilde anadili Arapça olan diğer bir öğrenciye Türkçe işlenen matematik dersinde platform aracılığıyla yardımcı olabilecektir. Aynı zamanda platform üzerinde farklı dilleri harmanlayarak kaynak paylaşımı yaparak mülteci çocukların öğrenmesindeki dil bariyerini ortadan kaldırıyoruz.
          </Typography>
          <Typography sx={{ m: 2 }}>
            Biz dünyanın en fazla mülteci barındıran ülkesi olan Türkiye’de yaşayan bir grup lise öğrencisi olarak mülteci çocukların eğitimlerinde yaşadıkları sorunlara duyarsız kalmak istemedik. Geliştirdiğimiz bu çatı platform aracılığıyla, bu öğrencilere eğitimlerinin her sürecinde destek olmayı hedefledik. Eğer bize ulaşmak isterseniz, şu adresten ulaşabilirsiniz:
          </Typography>
          <Typography sx={{ color: 'primary.main', m: 2 }}>biradimproject@gmail.com</Typography>
        </Paper>
        <Paper elevation={3} sx={{ width: 600, height: 1150, justifyContent: 'center', alignItems: 'center', m: 4 }}>
          <Typography sx={{ m: 2, mt: 5 }}>
            The main purpose of our project on the education of refugee children is to create a platform to support the refugee students who receive education under difficult situations. According to researchers, from 2020, there are approximately four million refugees in our country and almost 1.74 of them are children. Therefore, with this project, we are aiming to ensure that refugee children living in our country are able to receive education efficiently and to support children who have not completed their education.
          </Typography>
          <Typography sx={{ m: 2 }}>
            We enable refugee children whose education life is interrupted to follow their learning processes and help them complete their curriculum. We remove communication barriers between teacher and student. Thus, we facilitate the education process of students and communication between teachers working in different schools. Additionally, we help teachers to have the most up-to-date data on the refugee students who have to continue their education in different schools due to their families working seasonal jobs. We record the educational processes of the students in their previous schools on the platform. In this way, teachers can learn how much knowledge these students have on the subjects without needing any assessment, which also helps refugee children quickly fill their curriculum gaps with the help of teachers.
          </Typography>
          <Typography sx={{ m: 2 }}>
            The most significant problem that refugee children face is the language barrier caused by their lack of language proficiency. This language barrier causes refugee children to have difficulty following the lessons at their schools. With this project, we aim to match children with the same first language to enable them help each other through peer teaching. We believe that efficient learning for refugee students can be achieved through tutoring by students from higher grades who already took the classes speaking the same mother language. For example, a student from 8th grade speaking Arabic could help a student from 5th grade also speaking Arabic in topics such as math which are normally thaught in Turkish at schools. In addition, our platform removes the language barrier of refugee students by providing a blend of many resources in different languages.
          </Typography>
          <Typography sx={{ m: 2 }}>
            Biz dünyanın en fazla mülteci barındıran ülkesi olan Türkiye’de yaşayan bir grup lise öğrencisi olarak mülteci çocukların eğitimlerinde yaşadıkları sorunlara duyarsız kalmak istemedik. Geliştirdiğimiz bu çatı platform aracılığıyla, bu öğrencilere eğitimlerinin her sürecinde destek olmayı hedefledik. Eğer bize ulaşmak isterseniz, şu adresten ulaşabilirsiniz:
          </Typography>
          <Typography sx={{ color: 'primary.main', m: 2 }}>biradimproject@gmail.com</Typography>
        </Paper>
      </Stack>
    </React.Fragment>
  )

  const desktop = (
    <React.Fragment>
      <Stack direction="row" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <Paper elevation={3} sx={{ width: 600, height: 1150, justifyContent: 'center', alignItems: 'center', m: 4 }}>
          <Typography sx={{ m: 2, mt: 5 }}>
            Mülteci çocuklarının eğitimi projemizin temel amacı zor koşullar altında eğitim alan mülteci çocukların eğitimine destek olmak adına bir platform yaratmaktır. Yapılan araştırmalara göre 2020 yılı itibariyle ülkemizde yaklaşık dört milyon mülteci bulunmaktadır ve onların yaklaşık 1.74’ü çocuktur. Bu nedenle biz bu proje ile ülkemizde yaşamını sürdüren mülteci çocukların verimli bir şekilde eğitim alabilmelerini sağlamayı ve eğitimleri yarın kalan çocuklara destek olmayı amaçlıyoruz.
          </Typography>
          <Typography sx={{ m: 2 }}>
            Eğitim hayatları yarım kalan mülteci çocukların öğrenim süreçlerini takip edebilmelerini sağlayarak müfredatları tamamlamalarına yardımcı oluyoruz. Öğretmen ve öğrenci arasındaki iletişim engelini kaldırarak eğitim sürecini kolaylaştırmak ile beraber, farklı okullarda çalışan öğretmenler arası iletişimi kolaylaştırıyoruz . Mevsimlik işlerde çalışan aileleri sebebiyle farklı okullarda eğitimini sürdürmek zorunda kalan mülteci çocuklar hakkında en güncel verilere sahip olmalarına aracı oluyoruz. Geçmiş okullarındaki eğitim süreçlerini platforma kaydederek çocukların hangi konuda ne kadar bilgiye sahip olduklarını ölçme değerlendirmeye ihtiyaç duymadan öğretmenlere iletilmesini sağlıyoruz. Böylelikle öğretmenlerin yardımıyla mülteci çocukların müfredattaki eksiklerini hızlıca kapatmalarına yardımcı oluyoruz.
          </Typography>
          <Typography sx={{ m: 2 }}>
            Mülteci çocukların yaşadığı en büyük problem dil yetersizliğinden kaynaklanmaktadır, bu yüzden de okullarda bazı dersleri takip etmekte zorlanmaktadırlar. Biz bu projeyle ana dili aynı olan çocukları eşleştirip, akran öğrenmesi ile birbirlerine yardımcı olmasını sağlıyoruz. Üst dönemde halihazırda o dersi almış bir öğrencinin alt dönemdeki bir öğrenciye kendi ana dilini de kullanarak eğitim vermesinin en verimli öğrenmeyi sağlayacağı görüşünüzdeyiz. Örneğin 8. sınıf anadili Arapça olan bir öğrenci, 5. sınıftaki aynı şekilde anadili Arapça olan diğer bir öğrenciye Türkçe işlenen matematik dersinde platform aracılığıyla yardımcı olabilecektir. Aynı zamanda platform üzerinde farklı dilleri harmanlayarak kaynak paylaşımı yaparak mülteci çocukların öğrenmesindeki dil bariyerini ortadan kaldırıyoruz.
          </Typography>
          <Typography sx={{ m: 2 }}>
            Biz dünyanın en fazla mülteci barındıran ülkesi olan Türkiye’de yaşayan bir grup lise öğrencisi olarak mülteci çocukların eğitimlerinde yaşadıkları sorunlara duyarsız kalmak istemedik. Geliştirdiğimiz bu çatı platform aracılığıyla, bu öğrencilere eğitimlerinin her sürecinde destek olmayı hedefledik. Eğer bize ulaşmak isterseniz, şu adresten ulaşabilirsiniz:
          </Typography>
          <Typography sx={{ color: 'primary.main', m: 2 }}>biradimproject@gmail.com</Typography>
        </Paper>
        <Paper elevation={3} sx={{ width: 600, height: 1150, justifyContent: 'center', alignItems: 'center', m: 4 }}>
          <Typography sx={{ m: 2, mt: 5 }}>
            The main purpose of our project on the education of refugee children is to create a platform to support the refugee students who receive education under difficult situations. According to researchers, from 2020, there are approximately four million refugees in our country and almost 1.74 of them are children. Therefore, with this project, we are aiming to ensure that refugee children living in our country are able to receive education efficiently and to support children who have not completed their education.
          </Typography>
          <Typography sx={{ m: 2 }}>
            We enable refugee children whose education life is interrupted to follow their learning processes and help them complete their curriculum. We remove communication barriers between teacher and student. Thus, we facilitate the education process of students and communication between teachers working in different schools. Additionally, we help teachers to have the most up-to-date data on the refugee students who have to continue their education in different schools due to their families working seasonal jobs. We record the educational processes of the students in their previous schools on the platform. In this way, teachers can learn how much knowledge these students have on the subjects without needing any assessment, which also helps refugee children quickly fill their curriculum gaps with the help of teachers.
          </Typography>
          <Typography sx={{ m: 2 }}>
            The most significant problem that refugee children face is the language barrier caused by their lack of language proficiency. This language barrier causes refugee children to have difficulty following the lessons at their schools. With this project, we aim to match children with the same first language to enable them help each other through peer teaching. We believe that efficient learning for refugee students can be achieved through tutoring by students from higher grades who already took the classes speaking the same mother language. For example, a student from 8th grade speaking Arabic could help a student from 5th grade also speaking Arabic in topics such as math which are normally thaught in Turkish at schools. In addition, our platform removes the language barrier of refugee students by providing a blend of many resources in different languages.
          </Typography>
          <Typography sx={{ m: 2 }}>
          We are a group of highschool students living in Turkiye, home to more refugees than any other country in the world. We strive to share our privilege and avoid being insensitive about the problems refugee children experience in their education. Through this platform we developed, we aim to support these students in every step of their education. If you would like to contact us, you can reach us at:
          </Typography>
          <Typography sx={{ color: 'primary.main', m: 2 }}>biradimproject@gmail.com</Typography>
        </Paper>
      </Stack>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      {matches ? mobil : desktop}
    </React.Fragment>
  )
}