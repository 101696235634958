import React, { useContext, useState } from 'react';
import { Typography, Stack, Box, TextField, Grid, Card, CardContent, InputLabel, Select, FormControl, MenuItem, Button } from '@mui/material';
import { UserContext } from '../contexts/user.context';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';


export default function Resources() {


    const { currentUser } = useContext(UserContext)

    let userDetails = '';
    if (typeof (currentUser) !== 'undefined') {
        userDetails = currentUser;
    }

    const [selectValue, setSelectValue] = React.useState('')
    const [selectValueTwo, setSelectValueTwo] = React.useState('')
    const [selectValueThree, setSelectValueThree] = React.useState('')
    const [rList, setRList] = useState([]);




    const handleChange = (event) => {
        setSelectValue(event.target.value);
    };

    const handleChangeTwo = (event) => {
        setSelectValueTwo(event.target.value);
    };
    const handleChangeThree = (event) => {
        setSelectValueThree(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        window.location.reload();
    };

    const handleClickOpenTwo = async () => {
      
    };

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
  

    return (
        <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems:'center' }}>
            <Stack component="form" direction="vertical" onSubmit={handleSubmit}  sx={{display:'flex',}}>
                <Stack sx={{m:3}}>
                    <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                        <Typography variant="h7">Sınıf Seviyesi</Typography>
                    </Box>
                    <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 2, mt: 1 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sınıf Seviyesi</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectValue}
                                defaultValue={selectValue}
                                label="Sınıf"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value={"5"}>5</MenuItem>
                                <MenuItem value={"6"}>6</MenuItem>
                                <MenuItem value={"7"}>7</MenuItem>
                                <MenuItem value={"8"}>8</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Stack>
                <Stack sx={{m:3}}>
                <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                        <Typography variant="h7">Ders Adı</Typography>
                    </Box>
                    <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 2, mt: 1 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Ders Adı</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectValueTwo}
                                defaultValue={selectValueTwo}
                                label="Sınıf"
                                displayEmpty
                                onChange={handleChangeTwo}
                            >
                                <MenuItem value={"Matematik"}>Matematik</MenuItem>
                                <MenuItem value={"Fen"}>Fen</MenuItem>
                                <MenuItem value={"Türkçe"}>Türkçe</MenuItem>
                                <MenuItem value={"İngilizce"}>İngilizce</MenuItem>
                                <MenuItem value={"Sosyal"}>Sosyal</MenuItem>
                                <MenuItem value={"Din"}>Din</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Stack>
                <Stack sx={{m:3}}>
                    <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                        <Typography variant="h7">Ana Dil</Typography>
                    </Box>
                    <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 2, mt: 1 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Ana dil</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectValueThree}
                                defaultValue={selectValueThree}
                                label="Ana Dil"
                                displayEmpty
                                onChange={handleChangeThree}
                            >
                                <MenuItem value={"Arapça"}>Arapça عربي</MenuItem>
                                <MenuItem value={"Kürtçe"}>Kürtçe Kurdî</MenuItem>
                                <MenuItem value={"Peştuca"}>Peştuca کرد</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Stack>
            </Stack>
            <Stack>
                <Button variant="contained" sx={{ backgroundColor:'secondary', textTransform:'none',mb:3}}>Kaynak Ara</Button>
            </Stack>
            <Stack>
            {rList.map((comment) =>
                <Card sx={{ minHeight: 100, minWidth: 350, m: 3, display: 'flex' }}>
                    <CardContent>
                        <Typography variant="h7" component="div" >
                            {comment.teacherName}, {comment.teacherField}
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" sx={{ mb: 3 }} gutterBottom>
                            {comment.teacherContact}
                        </Typography>

                        <Typography variant="body2">
                            {comment.teacherInput}
                        </Typography>
                    </CardContent>
                </Card>
            )}
            </Stack>
        </Stack>
    );
}
