import React, { useEffect, useState, useContext } from 'react';
import { Typography, Paper, useMediaQuery, Stack, Button, Box, Divider, FormControl, Card, CardContent, Grid, CardActions, ListItemIcon, Select, MenuItem, InputLabel, Dialog, AppBar, Toolbar, List, ListItem, ListItemText, IconButton, Slide, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { DataGrid } from '@mui/x-data-grid';
import { UserContext } from '../contexts/user.context';
import { retrieveUserData, updateUser, enrollClass, retrieveTeacherComments } from '../utils/firebase/firebase.utils';
import { Link, useNavigate } from 'react-router-dom';
import {
    auth,
} from "../utils/firebase/firebase.utils";
import { useAuthState } from "react-firebase-hooks/auth";
const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: 'primary.main',
    elevation: 0,
    border: '2px solid gray',
    minHeight: 200,
    maxHeight: 700,
    width: 500,
    marginBottom: 20,
    marginTop: 20,
    justifyContent: 'start',
    alignItems: 'start',
    display: 'flex',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
        width: 450,
    }
}));

const StyledPaperFr = styled(Paper)(({ theme }) => ({
    backgroundColor: 'primary.main',
    elevation: 0,
    border: '2px solid gray',
    minHeight: 200,
    maxHeight: '100%',
    width: 500,
    marginBottom: 20,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
        width: 450,
    }
}));

const StyledPaperDialog = styled(Paper)(({ theme }) => ({
    backgroundColor: 'primary.main',
    elevation: 0,
    border: '2px solid gray',
    minHeight: 200,
    maxHeight: '100%',
    width: 700,
    marginBottom: 20,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
        width: 450,
    }
}));
const StyledStack = styled(Stack)(({ theme }) => ({

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Profile() {


    const { currentUser } = useContext(UserContext)

    const [user, loading, error] = useAuthState(auth);
    const [students, setStudents] = useState([]);
    const [qList, setQList] = useState([]);

    const navigate = useNavigate();
    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/login");
    }, [user, loading]);


    let userDetails = '';
    if (typeof (currentUser) !== 'undefined') {
        userDetails = currentUser;
    }


    const [value, setValue] = useState();
    const [open, setOpen] = React.useState(false);
    const [opentwo, setOpenTwo] = React.useState(false);

    const [selectValue, setSelectValue] = React.useState(userDetails.nativeLanguages)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleClickOpenTwo = async () => {
        const data = await retrieveTeacherComments(userDetails.uid);
        setQList(data);
        setOpenTwo(true);
    };

    const handleCloseTwo = () => {
        setOpenTwo(false);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        await updateUser(userDetails.uid, data.get('name'), selectValue, data.get('school'), data.get('city'), data.get('telefon'));
        window.location.reload();
    };

    const handleChange = (event) => {
        setSelectValue(event.target.value);
        console.log(userDetails.email);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'studentName', headerName: 'Öğrenci Adı', width: 130 },
        { field: 'studentId', headerName: 'Özel Kodu', width: 130 },

    ]
    const rows = [
    ];
    let counter = 1;

    if (userDetails.roles === 'teacher') {
        for (let i = 0; i < userDetails.students.length; i++) {
            rows.push({ id: counter, studentName: userDetails.studentName[i], studentId: userDetails.students[i] });
            counter++;
        }
    }


    const Form = (
        <React.Fragment>
            <Stack component="form" id="userformdata" onSubmit={handleSubmit} sx={{ alignItems: 'center' }}>
                <StyledPaperFr>
                    <Stack direction="column" sx={{ alignItems: 'center' }}>
                        <Stack sx={{ alignItems: 'center' }}>
                            <Typography variant="h5" sx={{ m: 2 }}>Kişisel Bilgilerin</Typography>
                            <Avatar sx={{ height: 50, width: 50, mb: 4, border: '2px solid gray' }}></Avatar>
                        </Stack>
                        <Stack sx={{ alignItems: 'center' }}>
                            <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                                <Typography variant="h7">İsim</Typography>
                            </Box>
                            <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 3, mt: 1 }}>
                                <TextField
                                    sx={{ width: '50ch' }}
                                    required
                                    name="name"
                                    id="outlined-required"
                                    label=""
                                    defaultValue={userDetails.name}
                                />
                            </Box>
                        </Stack>
                        <Stack sx={{ alignItems: 'center' }}>
                            <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                                <Typography variant="h7">Email</Typography>
                            </Box>
                            <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 3, mt: 1 }}>
                                <TextField
                                    sx={{ width: '50ch' }}
                                    required
                                    id="outlined-required"
                                    label=""
                                    defaultValue={userDetails.email}
                                />
                            </Box>
                        </Stack>
                        <Stack sx={{ alignItems: 'center' }}>
                            <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                                <Typography variant="h7">Okul Telefon Numarası</Typography>
                            </Box>
                            <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 3, mt: 1 }}>
                                <TextField
                                    sx={{ width: '50ch' }}
                                    required
                                    name="telefon"
                                    id="outlined-required"
                                    label=""
                                    defaultValue={userDetails.telefon}
                                />
                            </Box>
                        </Stack>
                        <Stack sx={{ alignItems: 'center' }}>
                            <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                                <Typography variant="h7">Şehir</Typography>
                            </Box>
                            <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 3, mt: 1 }}>
                                <TextField
                                    sx={{ width: '50ch' }}
                                    required
                                    id="outlined-required"
                                    label=""
                                    name="city"
                                    defaultValue={userDetails.city}
                                />
                            </Box>
                        </Stack>
                        <Stack sx={{ alignItems: 'center' }}>
                            <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                                <Typography variant="h7">Okul</Typography>
                            </Box>
                            <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 3, mt: 1 }}>
                                <TextField
                                    sx={{ width: '50ch' }}
                                    required
                                    name="school"
                                    id="outlined-required"
                                    label=""
                                    defaultValue={userDetails.currentSchool}
                                />
                            </Box>
                        </Stack>
                        <Stack sx={{ alignItems: 'center' }}>
                            <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                                <Typography variant="h7">Ana Dil</Typography>
                            </Box>
                            <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 5, mt: 1 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Ana dil</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectValue}
                                        defaultValue={selectValue}
                                        label="Ana Dil"
                                        displayEmpty
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"Türkçe"}>Türkçe</MenuItem>
                                        <MenuItem value={"Arapça"}>Arapça عربي</MenuItem>
                                        <MenuItem value={"Kürtçe"}>Kürtçe Kurdî</MenuItem>
                                        <MenuItem value={"Peştuca"}>Peştuca کرد</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                        <Button color="secondary" autoFocus sx={{ color: 'white', display: 'flex', borderRadius: 3 }} variant="contained" type="submit" onClick={handleClose}>
                            Kaydet
                        </Button>
                    </Stack>
                </StyledPaperFr>
            </Stack>
        </React.Fragment>
    );

    const DialogDesign = (
        <React.Fragment>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                            Kişisel Bilgilerin
                        </Typography>
                        <Button autoFocus sx={{ color: 'white', display: 'flex' }} onClick={handleClose}>
                            Kaydet
                        </Button>
                    </Toolbar>
                </AppBar>

                {Form}
            </Dialog>
        </React.Fragment>
    );

    var commentsMath = [];
    var commentsScience = [];
    var commentsTurkish = [];
    var commentsEnglish = [];
    var commentsReligion = [];
    var commentsSocial = [];

    for (const key in qList) {
        if (qList[key].teacherField === "Matematik") {
            commentsMath.push({ teacherInput: qList[key].teacherInput, teacherName: qList[key].teacherName, teacherField: qList[key].teacherField, teacherContact: qList[key].teacherContact, });
        }
        else if (qList[key].teacherField === "Fen") {
            commentsScience.push({ teacherInput: qList[key].teacherInput, teacherName: qList[key].teacherName, teacherField: qList[key].teacherField, teacherContact: qList[key].teacherContact, });
        }
        else if (qList[key].teacherField === "Türkçe") {
            commentsTurkish.push({ teacherInput: qList[key].teacherInput, teacherName: qList[key].teacherName, teacherField: qList[key].teacherField, teacherContact: qList[key].teacherContact, });
        }
        else if (qList[key].teacherField === "İngilizce") {
            commentsEnglish.push({ teacherInput: qList[key].teacherInput, teacherName: qList[key].teacherName, teacherField: qList[key].teacherField, teacherContact: qList[key].teacherContact, });
        }
        else if (qList[key].teacherField === "Sosyal") {
            commentsSocial.push({ teacherInput: qList[key].teacherInput, teacherName: qList[key].teacherName, teacherField: qList[key].teacherField, teacherContact: qList[key].teacherContact, });
        }
        else if (qList[key].teacherField === "Din") {
            commentsReligion.push({ teacherInput: qList[key].teacherInput, teacherName: qList[key].teacherName, teacherField: qList[key].teacherField, teacherContact: qList[key].teacherContact, });
        }
    }

    const DialogStuDetails = (
        <React.Fragment>
            <Dialog
                fullScreen
                open={opentwo}
                onClose={handleCloseTwo}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseTwo}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                            Öğretmenlerinden yorumlar
                        </Typography>

                    </Toolbar>
                </AppBar>
                <Stack sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <StyledPaperDialog >
                    <Stack sx={{justifyContent:'center', alignItems:'center'}}>

                        <Typography>Matematik Yorumları</Typography>
                        <Grid direction="column" sx={{ justifyContent: 'start', alignItems: 'start', display: 'flex', overflow: 'auto', }}>
                            {commentsMath.map((comment) =>
                                <Card sx={{ minHeight: 100, minWidth: 350, m: 3, display: 'flex' }}>
                                    <CardContent>
                                        <Typography variant="h7" component="div" >
                                            {comment.teacherName}, {comment.teacherField}
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" sx={{ mb: 3 }} gutterBottom>
                                            {comment.teacherContact}
                                        </Typography>

                                        <Typography variant="body2">
                                            {comment.teacherInput}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )}
                        </Grid>
                        </Stack>

                    </StyledPaperDialog>
                    <StyledPaperDialog >
                            <Stack sx={{justifyContent:'center', alignItems:'center'}}>
                        <Typography>Fen Yorumları</Typography>
                        <Grid direction="column" sx={{ justifyContent: 'start', alignItems: 'start', display: 'flex', overflow: 'auto', }}>
                            {commentsScience.map((comment) =>
                                <Card sx={{ minHeight: 100, minWidth: 350, m: 3, display: 'flex' }}>
                                    <CardContent>
                                        <Typography variant="h7" component="div" >
                                            {comment.teacherName}, {comment.teacherField}
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" sx={{ mb: 3 }} gutterBottom>
                                            {comment.teacherContact}
                                        </Typography>

                                        <Typography variant="body2">
                                            {comment.teacherInput}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )}
                        </Grid>
                        </Stack>
                    </StyledPaperDialog>
                    <StyledPaperDialog >
                        <Stack sx={{justifyContent:'center', alignItems:'center'}}>
                        <Typography>İngilizce Yorumları</Typography>
                        <Grid direction="column" sx={{ justifyContent: 'start', alignItems: 'start', display: 'flex', overflow: 'auto', }}>
                            {commentsEnglish.map((comment) =>
                                <Card sx={{ minHeight: 100, minWidth: 350, m: 3, display: 'flex' }}>
                                    <CardContent>
                                        <Typography variant="h7" component="div" >
                                            {comment.teacherName}, {comment.teacherField}
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" sx={{ mb: 3 }} gutterBottom>
                                            {comment.teacherContact}
                                        </Typography>

                                        <Typography variant="body2">
                                            {comment.teacherInput}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )}
                        </Grid>
                        </Stack>
                    </StyledPaperDialog>
                    <StyledPaperDialog >
                        <Stack sx={{justifyContent:'center', alignItems:'center'}}>
                        <Typography>Sosyal Bilgiler Yorumları</Typography>

                        <Grid direction="column" sx={{ justifyContent: 'start', alignItems: 'start', display: 'flex', overflow: 'auto', }}>
                            {commentsSocial.map((comment) =>
                                <Card sx={{ minHeight: 100, minWidth: 350, m: 3, display: 'flex' }}>
                                    <CardContent>
                                        <Typography variant="h7" component="div" >
                                            {comment.teacherName}, {comment.teacherField}
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" sx={{ mb: 3 }} gutterBottom>
                                            {comment.teacherContact}
                                        </Typography>

                                        <Typography variant="body2">
                                            {comment.teacherInput}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )}
                        </Grid>
                        </Stack>
                    </StyledPaperDialog>
                    <StyledPaperDialog >
                        <Stack sx={{justifyContent:'center', alignItems:'center'}}>
                        <Typography>Din Yorumları</Typography>
                        <Grid direction="column" sx={{ justifyContent: 'start', alignItems: 'start', display: 'flex', overflow: 'auto', }}>
                            {commentsReligion.map((comment) =>
                                <Card sx={{ minHeight: 100, minWidth: 350, m: 3, display: 'flex' }}>
                                    <CardContent>
                                        <Typography variant="h7" component="div" >
                                            {comment.teacherName}, {comment.teacherField}
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" sx={{ mb: 3 }} gutterBottom>
                                            {comment.teacherContact}
                                        </Typography>

                                        <Typography variant="body2">
                                            {comment.teacherInput}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )}
                        </Grid>
                        </Stack>
                    </StyledPaperDialog>
                </Stack>
            </Dialog>
        </React.Fragment>
    );


    const kisiselBilgi = (
        <Stack sx={{ alignItems: 'center' }}>
            <StyledPaperFr>
                <Stack sx={{ alignItems: 'center' }}>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Typography variant="h4" sx={{ m: 3, color: 'primary.main' }} >Merhaba {userDetails.name}</Typography>
                        <Typography variant="h5" sx={{ m: 2 }}>Kişisel Bilgilerin</Typography>
                        <Avatar sx={{ height: 50, width: 50, mb: 4, border: '2px solid gray' }}></Avatar>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h7">İsim</Typography>
                        </Box>
                        <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h6">{userDetails.name}</Typography>
                        </Box>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h7">Email</Typography>
                        </Box>
                        <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h6">{userDetails.email}</Typography>
                        </Box>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h7">Telefon Numarası</Typography>
                        </Box>
                        <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h6">{userDetails.telefon}</Typography>
                        </Box>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h7">Şehir</Typography>
                        </Box>
                        <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h6">{userDetails.city}</Typography>
                        </Box>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h7">Okul</Typography>
                        </Box>
                        <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h6" noWrap>{userDetails.currentSchool}</Typography>
                        </Box>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h7">Ana Dil</Typography>
                        </Box>
                        <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h6" noWrap>{userDetails.nativeLanguages}</Typography>
                        </Box>
                    </Stack>
                    <Button variant="contained" color="secondary" onClick={handleClickOpen} sx={{ color: 'white', mt: 3, mb: 3, borderRadius: 3 }}>Güncelle</Button>
                    {DialogDesign}
                </Stack>
            </StyledPaperFr>
        </Stack>
    );

    const handleSubmitSinif = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        await enrollClass(userDetails.uid, data.get('sinifkodu'));
        window.location.reload();
    };

    let previousClassCodes = [''];

    if (userDetails.roles !== 'teacher') {
        previousClassCodes = userDetails.previousClassCodes;
    }

    const studentView = (
        <Stack sx={{ alignItems: 'center' }}>
            <StyledPaperFr>
                <Stack sx={{ alignItems: 'center' }}>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Typography variant="h5" sx={{ m: 2 }}>Sınıf Bilgileri</Typography>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'center', mb: 3 }}>
                            <Typography variant="h6">Şu an Kayıtlı Olduğun Sınıf</Typography>
                        </Box>
                        <Box sx={{ width: 100, height: 40, display: 'flex', border: '1px solid gray', justifyContent: 'center' }}>
                            <Typography variant="h6">{userDetails.currentClassCode}</Typography>
                        </Box>
                    </Stack>
                    <Stack component="form" onSubmit={handleSubmitSinif} sx={{ mt: 5 }}>
                        <Box sx={{ width: 300, height: 40, display: 'flex', justifyContent: 'start', mb: 3, mt: 1 }}>
                            <TextField
                                sx={{ width: '50ch' }}
                                required
                                name="sinifkodu"
                                id="outlined-required"
                                label=""
                            />
                        </Box>
                        <Button variant="contained" type="submit" sx={{ color: 'white', textTransform: 'none' }}> Yeni sınıfa kaydol </Button>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'center', mb: 1, mt: 3 }}>
                            <Typography variant="h6">Önceki Sınıfların:</Typography>
                        </Box>

                        <List sx={{ justifyContent: 'start' }}>
                            {
                                previousClassCodes.map((value) =>
                                    <h4>{value}</h4>
                                )}
                        </List>
                        <Button onClick={handleClickOpenTwo} variant="contained" sx={{ color: 'white', textTransform: 'none', height: 50, m: 5 }}>Öğretmenlerinin yorumlarını gör</Button>
                        {DialogStuDetails}
                    </Stack>
                </Stack>
            </StyledPaperFr>
        </Stack>
    );

    const teacherView = (
        <Stack sx={{ alignItems: 'center' }}>
            <StyledPaper>
                <Stack sx={{ alignItems: 'center', mt: 6 }}>
                    <Stack sx={{ alignItems: 'center', mb: 3 }}>
                        <Typography variant="h4" sx={{ m: 3, }} >Sevgili Öğretmen</Typography>
                        <Box sx={{ border: '1px solid gray', width: 400 }}>
                            <Stack sx={{ alignItems: 'center' }}>
                                <Typography variant="h5" sx={{ m: 3, color: 'secondary.main' }} >Özel kodunuz: {userDetails.teacherId}</Typography>
                            </Stack>
                        </Box>
                        <Typography variant="h7" sx={{ ml: 4, mr: 4, mt: 4, }} >Bu kodu öğrencilerinizle paylaşarak kendi profil saylarından sizin sınıfınıza kayıt olmasını sağlayabilirsiniz. Bu kodu girmiş olan öğrencilerinizi yukarıdaki menüden öğrenciler kısmından görüntüleyebilirsiniz.</Typography>
                    </Stack>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Box sx={{ width: 300, height: 20, display: 'flex', justifyContent: 'start' }}>
                        </Box>
                    </Stack>
                </Stack>
            </StyledPaper>
        </Stack>
    );
    const theme = useTheme();
    let teacherCheck = false;
    if (userDetails.roles === 'teacher') {
        teacherCheck = true;
    }
    if (user) {
        return (
            <React.Fragment>
                <StyledStack direction={{ xl: 'row', lg: 'row', sm: 'column', xs: 'column' }} spacing={3} sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    {kisiselBilgi}
                    {teacherCheck ? teacherView : studentView}
                    {teacherCheck ? <div style={{ height: 400, width: 400 }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                        />
                    </div> : <></>}

                </StyledStack>
            </React.Fragment>)
    }
    else {
        return (<Typography sx={{ color: 'white' }}>Selam</Typography>);
    }

}

