import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  doc,
  addDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import firebaseConfig from '../../components/firebaseConfig.json'

const course = 'APCalc'

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);


const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
    prompt: 'select_account',
  });

const retrieveUserData = async (userAuth) => {
  const q = query(collection(db, "users"), where("uid", "==", userAuth));
  const ke = await getDocs(q);
  return ke.docs.map(doc => ({...doc.data()}));
}

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const registerWithEmailAndPassword = async (name, email, password, nativeLanguages, currentSchool, city) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      nativeLanguages,
      currentSchool,
      roles:"",
      currentClassCode:"",
      teacherId:"",
      telefon:"",
      subjectInput:"",
      students:[],
      previousClassCodes:[],
      teacherInputs: [],
      teacherNameInputs: [],
      teacherContactInputs:[],
      city,
    });

  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = async () => await signOut(auth);

//update user
const updateUser = async (useruid,nameForm, nativeLanguage, school, cityName,telefon) => {
  const q = query(collection(db, "users"), where("uid", "==", useruid));
  const querySnapshot = await getDocs(q);
  const userList = [];
  querySnapshot.forEach((doc) => {
      userList.push(doc.id);
    });
  const userDocName = userList[0];
  const userRef = doc(db, "users", userDocName);
  await updateDoc(userRef, {
    name: nameForm,
    nativeLanguages: nativeLanguage,
    currentSchool:school,
    telefon: telefon,
    city: cityName,
  })
  return userDocName;
}

//enroll in a class
const enrollClass = async (useruid, classCode) => {
  const q = query(collection(db, "users"), where("uid", "==", useruid));
  const q2 = query(collection(db, "users"), where("teacherId", "==", classCode));
  const querySnapshot = await getDocs(q);
  const querySnapshot2 = await getDocs(q2);

  const userList = [];
  querySnapshot.forEach((doc) => {
      userList.push(doc.id);
      userList.push(doc.data());
    });
  const teacherList = [];
  querySnapshot2.forEach((doc) => {
    teacherList.push(doc.id);
    teacherList.push(doc.data());
    });

  const userDocName = userList[0];
  const userDocDetails = userList[1];
  console.log(userDocDetails.currentClassCode)
  const userRef = doc(db, "users", userDocName);
  const teacherRef = doc(db, "users", teacherList[0]);
  console.log(teacherList[1])
  if(teacherList[1].roles === 'teacher'){
    if(userDocDetails.currentClassCode === ''){
      await updateDoc(userRef, {
        currentClassCode:classCode
      });
      await updateDoc(teacherRef, {
        students:arrayUnion(userDocDetails.uid),
        studentName:arrayUnion(userDocDetails.name)
      })
    }
    else{
      if(userDocDetails.currentClassCode !== classCode || !userDocDetails.previousClassCodes.includes(classCode)){
        await updateDoc(userRef, {
          previousClassCodes: arrayUnion(userDocDetails.currentClassCode),
          currentClassCode:classCode
        });
        await updateDoc(teacherRef, {
          students:arrayUnion(userDocDetails.uid),
          studentName:arrayUnion(userDocDetails.name)
        });
      }
    }
  }
  return userDocName;
}


const teacherInputFunc = async (teacheruid ,stuuid, teacherInput, teacherName, teacherContact, teacherField) => {

  const userRef = collection(db, "comments");
  await addDoc(userRef, {
    teacheruid: teacheruid,
    studentuid: stuuid,
    teacherInput: teacherInput,
    teacherName: teacherName,
    teacherContact: teacherContact,
    teacherField: teacherField,
  });
}


const retrieveTeacherComments = async (stuuid) =>{
  const q = query(collection(db, "comments"), where("studentuid", "==", stuuid));
  const querySnapshot = await getDocs(q);
  const teachercommentlist = [];
  querySnapshot.forEach((doc) => {
    teachercommentlist.push(doc.data());
  });

  return teachercommentlist;
}

const resourceInputFunc = async (teacheruid ,stuuid, teacherInput, teacherName, teacherContact, teacherField) => {

  const userRef = collection(db, "comments");
  await addDoc(userRef, {
    owneruid: teacheruid,
    ownername: stuuid,
    ownerinput: teacherInput,
    resourcelang: teacherName,
    resourceclass: teacherContact,
    resourcefield: teacherField,
  });
}

const retrieveResources = async (stuuid) =>{
  const q = query(collection(db, "resources"), where("studentuid", "==", stuuid));
  const querySnapshot = await getDocs(q);
  const teachercommentlist = [];
  querySnapshot.forEach((doc) => {
    teachercommentlist.push(doc.data());
  });

  return teachercommentlist;
}
const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback);

export {
  teacherInputFunc,
  updateUser,
  auth,
  db,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  enrollClass,
  logout,
  onAuthStateChangedListener,
  retrieveUserData,
  retrieveTeacherComments
};
